import qs from 'qs'

export default {
	data() {
		return {
			pagina: {
				translations: []
			},
		}
	},
	computed: {
		isValid() {
			return this.pagina != null && this.pagina.id
		},
		blocks() {
			if(this.isValid && this.translation)
				return this.translation.content
			return []
		},
		translation() {
			if (!this.isValid)
				return {}
			const translation = this.pagina.translations.find(translation => translation.locale == this.$store.state.locale)
			return translation || {}
		},
		tags() {
			if(this.isValid && this.translation.tags)
				return this.translation.tags.map(tag => ({
					name: tag.name,
					content: tag.value,
					vmid: tag.name
				}))
			return []
		},
		navigation() {
			if(this.isValid)
				return this.$store.getters['menus/pagina']
			return {}
		},
		hasNavigation() {
			return this.pagina.template == "pagewithmenu" && this.navigation.id
		},
		isNoticia() {
			return this.$route.name == 'Noticia'
		},
		isHome() {
			return this.$route.name == 'Home'
		},
		isTrabalhe() {
			return this.$route.name == 'Trabalhe'
		},
		isIndustrias() {
			return this.$route.name == 'Industrias'
		}
	},
	watch: {
		pagina: function() {
			if (this.isValid) {
				if (this.isHome || this.isTrabalhe || this.isNoticia || this.isIndustrias) return
				if (this.pagina.category?.menu)
					this.$store.dispatch('menus/getPagina', this.pagina.category.menu.id)
				else if (this.pagina.category.category.menu)
					this.$store.dispatch('menus/getPagina', this.pagina.category.category.menu.id)
			}
		}
	},
	metaInfo() {
		return {
			title: this.translation.title,
			meta: this.tags
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.loadPagina(to.params)
		})
	},
	beforeRouteUpdate(to, from, next) {
		this.loadPagina(to.params)
		next()
	},
	methods: {
		loadPagina({ category, subcategory, slug, locale = 'pt-br' }) {
			let params = { category }
			const prefix = this.isNoticia ? `news` : `pages`
			if (slug)
				params['sub-category'] = subcategory
			else
				slug = subcategory
			if (locale)
				this.$store.state.locale = locale
			if (this.isHome) {
				params = { category: 'home' }
				slug = 'pagina-inicial'
			}
			if (this.isTrabalhe) {
				params = { category: 'trabalhe-conosco' }
				slug = 'trabalhe-conosco'
			}
			if (this.isIndustrias) {
				params = { category: 'industrias' }
				slug = 'industrias'
			}
			this.$axios
				.get(`${prefix}/${this.$store.state.locale}/${slug}?${qs.stringify(params)}`)
				.then(response => {
					this.pagina = this.isNoticia ? response.data.news : response.data.page
				})
		},
		parseNavigation(item) {
			console.log(item)
			// if (item.parent)
			// 	return this.parseNavigation(item.parent)
			// return this.parseChildren(item)
		},
		parseChildren(item) {
			return item.children.map(child => {
				return {
					external: false,
					title: child.title,
					children: child.children ? this.parseChildren(child || {}) : [],
					link: {
						name: `Pagina`,
						params: {
							grandSlug: this.$route.params.grandSlug,
							parentSlug: item.slug,
							slugSon: child.slug
						}
					}
			}})
		},
	},
}
